import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Services from 'components/Services'
import RequestOffer from 'components/RequestOffer'

import 'styles/templates/ServicesTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title: banner_title
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      description: content
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const ServicesTemplate = ({
  data: {
    page: {
      path,
      acf: { title, image },
      description,
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={image.localFile.childImageSharp.fixed.src}
        article
      />
      <div className="services-template">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-6 pt-5 text-center text-lg-left">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        <div className="container text-center pt-5 px-lg-5">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <Services />

        <div>
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

export default ServicesTemplate
